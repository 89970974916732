<template>
  <div>
    <v-dialog
      v-model="addEditDialog"
      persistent
      max-width="700px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ editMode ? $t('Update Shareholder') : $t('Add New Shareholder') }}
          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon class="mdil-36px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="mb-14"
                  style="position: relative"
                >
                  <app-image-uploader
                    v-if="addEditDialog"
                    width="100%"
                    height="150px"
                    label="Profile picture"
                    :parent-image-url="formData.cover_picture"
                    @image-selected="formData.cover_picture = $event"
                    @is-image-updated="formData.cover_image_updated = $event"
                  />

                  <app-image-uploader
                    v-if="addEditDialog"
                    width="115px"
                    height="115px"
                    classes="rounded-pill"
                    parent-classes="justify-center"
                    parent-style="position: absolute; bottom: -50px; left: 50%; transform: translateX(-50%)"
                    label="Profile picture"
                    :parent-image-url="formData.profile_picture"
                    @image-selected="formData.profile_picture = $event"
                    @is-image-updated="formData.profile_image_updated = $event"
                  />
                </v-col>

                <v-col
                  class="px-1 pb-3 pt-4"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Shareholder Name')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.username"
                      :label="`* ${$t('Shareholder Name')}`"
                      outlined
                      dense
                      :hint="$t('This name use to login')"
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Full Name')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.first_name"
                      :label="`* ${$t('Full Name')}`"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Share Percentage %')"
                    rules="required|max_value:100|min_value:1"
                  >
                    <v-currency-field
                      v-model="formData.share_percentage"
                      :label="`* ${$t('Share Percentage %')}`"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Phone')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.phone"
                      :label="`* ${$t('Phone')}`"
                      outlined
                      dense
                      type="tel"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Type')"
                    rules="required"
                  >
                    <v-combobox
                      v-model="formData.type"
                      :label="`* ${$t('Type')}`"
                      :items="shareholderTypesList"
                      outlined
                      dense
                      :error-messages="errors"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t('Press enter to create to record') }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('ID Cart')"
                    rules="required"
                  >
                    <v-text-field
                      v-model.number="formData.id_cart"
                      :label="`* ${$t('ID Cart')}`"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Occupation')"
                  >
                    <v-text-field
                      v-model="formData.occupation"
                      :label="$t('Occupation')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Degree')"
                  >
                    <v-text-field
                      v-model="formData.degree"
                      :label="$t('Degree')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Nickname')"
                  >
                    <v-text-field
                      v-model="formData.nickname"
                      :label="$t('Nickname')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Business Name')"
                  >
                    <v-text-field
                      v-model="formData.business_name"
                      :label="$t('Business Name')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Martial Status')"
                  >
                    <v-radio-group
                      v-model="formData.marital_status"
                      row
                      class="mt-0"
                      :error-messages="errors"
                    >
                      <v-radio
                        :label="$t('Single')"
                        :value="1"
                        :ripple="false"
                      />
                      <v-radio
                        :label="$t('Married')"
                        :value="2"
                        :ripple="false"
                      />
                    </v-radio-group>
                  </validation-provider>
                </v-col>

                <v-col
                  v-if="formData.marital_status == 2"
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Husband/Wife Name')"
                  >
                    <v-text-field
                      v-model="formData.spouse_name"
                      :label="$t('Husband/Wife Name')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Mother Name')"
                  >
                    <v-text-field
                      v-model="formData.mother_name"
                      :label="$t('Mother Name')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                  :sm="formData.marital_status == 2 ? 12 : 6"
                  :md="formData.marital_status == 2 ? 12 : 6"
                  :lg="formData.marital_status == 2 ? 12 : 6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Dos Number Tax Office')"
                  >
                    <v-text-field
                      v-model="formData.dos_number_tax_office"
                      :label="$t('Dos Number Tax Office')"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Address')"
                  >
                    <v-textarea
                      v-model="formData.address"
                      :label="$t('Address')"
                      outlined
                      rows="3"
                      dense
                      no-resize
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  v-if="formData.marital_status == 2"
                  class="px-1"
                  cols="12"
                >
                  <v-row
                    no-gutters
                    style="border: 1px solid #ccc"
                    class="py-1 rounded-sm mb-4"
                  >
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                      class="px-2 d-none d-sm-flex"
                    >
                      {{ $t('Child Name') }}
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                      class="px-2 d-none d-sm-flex"
                    >
                      {{ $t('Date of Birth') }}
                    </v-col>

                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      lg="3"
                      class="px-2 d-none d-sm-flex"
                    >
                      {{ $t('Occupation') }}
                    </v-col>

                    <!-- For Mobile -->
                    <v-col
                      cols="10"
                      class="px-2 d-flex d-sm-none"
                    >
                      {{ $t('Children') }}
                    </v-col>
                    <!-- End |For Mobile -->

                    <v-col
                      cols="2"
                      sm="1"
                      md="1"
                      lg="1"
                      class="px-2 text-end"
                    >
                      <v-icon
                        color="success"
                        @click="addRow()"
                      >
                        mdil-plus-box
                      </v-icon>
                    </v-col>
                  </v-row>

                  <v-row
                    v-for="(baby, index) in formData.babies"
                    :key="index"
                    no-gutters
                  >
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                      class="px-1"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Child Name')"
                        rules="required"
                      >
                        <v-text-field
                          v-model="baby.name"
                          :label="$t('Child Name')"
                          outlined
                          dense
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                      class="px-1"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Birth Date')"
                      >
                        <app-date-picker
                          v-model="baby.dob"
                          :label="$t('Birth Date')"
                          outlined
                          dense
                          is-dob
                          :errors="errors"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      cols="11"
                      sm="3"
                      md="3"
                      lg="3"
                      class="px-1"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Occupation')"
                      >
                        <v-text-field
                          v-model="baby.occupation"
                          :label="$t('Occupation')"
                          outlined
                          dense
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      cols="1"
                      sm="1"
                      md="1"
                      lg="1"
                      class="px-1 pt-2 text-end"
                    >
                      <v-icon
                        color="error"
                        @click="removeRow()"
                      >
                        mdil-delete
                      </v-icon>
                    </v-col>

                    <v-col cols="12">
                      <v-divider
                        v-show="index != formData.babies.length - 1"
                        class="mb-5"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <v-btn
                type="submit"
                color="primary"
                depressed
                small
              >
                <v-icon left>
                  mdil-check
                </v-icon>
                <span class="pe-2">
                  {{ editMode ? $t('Save') : $t('Add') }}
                </span>
              </v-btn>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    addEditDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
    editMode: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters([
      'shareholderTypesList',
      'banksList',
      'governorateList',
    ])
  },

  methods: {
    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },

    addRow() {
      this.formData.babies.push({})
    },

    removeRow(index) {
      this.formData.babies.splice(index, 1)
    },

    submit() {
      let fd = new FormData()
      for (let key in this.formData) {
        if (this.formData[key]) {
          if (key !== 'profile_picture' && key !== 'cover_picture' && (typeof this.formData[key] == 'object' || Array.isArray(this.formData[key]))) {
            fd.append(key, JSON.stringify(this.formData[key]))
          } else {
            fd.append(key, this.formData[key])
          }
        }
      }

      if (this.editMode) {
        axios.put(`shareholder/${this.formData.id}/`, fd).then(() => {
          this.$_notify('Updated successfully')
          this.closeDialog()
          this.$emit('reset-table-data')
        })
      } else {
        axios.post('shareholder/', fd).then(() => {
          this.$_notify('Added successfully')
          this.closeDialog()
          this.$emit('reset-table-data')
        })
      }
    }
  },
}
</script>
