<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-card outlined>
          <v-card-text>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('Full Name') }}</th>
                    <th>{{ $t('Nationality') }}</th>
                    <th style="width: 200px" />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in companyResidences"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td>{{ row.full_name }}</td>
                    <td>{{ row.nationality }}</td>
                    <td class="text-center">
                      <v-btn
                        color="info"
                        small
                        text
                        depressed
                        @click="viewResidence(row)"
                      >
                        <v-icon left>
                          mdi-eye
                        </v-icon>
                        {{ $t('View') }}
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <show-residence
      :id="id"
      :show-dialog="showDialog"
      @close="showDialog = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import ShowResidence from './ShowResidence.vue'

export default {
  components: {
    ShowResidence,
  },

  data() {
    return {
      companyResidences: [],
      id: 0,
      addDialog: false,
      showDialog: false,
    }
  },

  mounted() {
    this.getResidences()
  },

  methods: {
    getResidences() {
      axios.get('residence/client/residences/').then(res => {
        this.companyResidences = res.data
      })
    },

    viewResidence(row) {
      this.id = row.id
      this.showDialog = true
    }
  },
}
</script>

<style lang="scss" scoped></style>
