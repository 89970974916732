<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-card outlined>
          <v-card-text>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th
                      style="width: 50px"
                      class="text-center"
                    >
                      #
                    </th>
                    <th style="width: 80px" />
                    <th>{{ $t('Shareholder Name') }}</th>
                    <th>{{ $t('Code') }}</th>
                    <th>{{ $t('Full Name') }}</th>
                    <th>{{ $t('Share Percentage %') }}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(holder, index) in shareholders"
                    :key="index"
                  >
                    <td class="text-center">
                      {{ index + 1 }}
                    </td>
                    <td class="py-1">
                      <v-avatar size="45">
                        <img
                          :src="holder.profile_picture ? `${$_file_path()}${holder.profile_picture}?token=${$_auth_token()}`: $_profile_placeholder()"
                          :alt="holder.username"
                        >
                      </v-avatar>
                    </td>
                    <td>{{ holder.username }}</td>
                    <td>{{ holder.code }}</td>
                    <td>{{ holder.first_name }}</td>
                    <td>{{ holder.share_percentage }}%</td>
                    <td class="text-center">
                      <v-btn
                        depressed
                        small
                        @click="view(holder)"
                      >
                        <v-icon left>
                          mdil-eye
                        </v-icon>
                        {{ $t('View') }}
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <view-info
      :view-dialog="viewDialog"
      :instance="formData"
      @close="viewDialog = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import ViewInfo from './ViewInfo.vue'

export default {
  components: {
    ViewInfo
  },

  data() {
    return {
      shareholders: [],
      viewDialog: false,
    }
  },

  mounted() {
    this.getShareholders()
  },

  methods: {
    getShareholders() {
      axios.get('company/client/my-company-shareholders').then(res => {
        this.shareholders = res.data
      })
    },

    view(holder) {
      this.formData = { ...holder }
      this.viewDialog = true
    },
  },
}
</script>
