<template>
  <div>
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('Profile Picture') }}</th>
            <th>{{ $t('National Card Picture') }}</th>
            <th>{{ $t('Social Order Picture') }}</th>
            <th>{{ $t('Full Name') }}</th>
            <th>{{ $t('Social Date') }}</th>
            <th>{{ $t('Salary') }}</th>
            <th>{{ $t('Last Payment Date') }}</th>
            <th
              style="width: 100px"
              class="text-center"
            >
              <v-btn
                depressed
                color="primary"
                small
                @click="addSocialSecurity()"
              >
                <v-icon
                  class="mdil-24px"
                  left
                >
                  mdil-plus
                </v-icon>
                {{ $t('Add New') }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in socialSecurities"
            :key="index"
            :class="{
              'bg-error-tonal': isLastPaymentDateOld(row)
            }"
          >
            <td>{{ index + 1 }}</td>
            <td class="py-1">
              <a
                :href="`${row.profile_picture}?token=${$_auth_token()}`"
                target="_blank"
              >
                <div style="width: 50px; height: 50px">
                  <img
                    :src="`${row.profile_picture}?token=${$_auth_token()}`"
                    style="width: 100%; height: 100%; object-fit: cover"
                    class="rounded"
                    alt=""
                  >
                </div>
              </a>
            </td>
            <td class="py-1">
              <a
                :href="`${row.id_card_picture}?token=${$_auth_token()}`"
                target="_blank"
              >
                <div style="width: 50px; height: 50px">
                  <img
                    :src="`${row.id_card_picture}?token=${$_auth_token()}`"
                    style="width: 100%; height: 100%; object-fit: cover"
                    class="rounded"
                    alt=""
                  >
                </div>
              </a>
            </td>
            <td class="py-1">
              <a
                v-if="row.order_picture"
                :href="`${row.order_picture}?token=${$_auth_token()}`"
                target="_blank"
              >
                <div style="width: 50px; height: 50px">
                  <img
                    :src="`${row.order_picture}?token=${$_auth_token()}`"
                    style="width: 100%; height: 100%; object-fit: cover"
                    class="rounded"
                    alt=""
                  >
                </div>
              </a>
            </td>
            <td>{{ row.full_name }}</td>
            <td>{{ row.input_date }}</td>
            <td>{{ $_number_format(row.salary) }}</td>
            <td>{{ row.last_payment.input_date }}</td>
            <td class="text-center">
              <v-btn
                class="bg-info-tonal info--text"
                small
                text
                depressed
                @click="viewSocialSecurity(row)"
              >
                <v-icon left>
                  mdi-eye
                </v-icon>
                {{ $t('View') }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <add-social-security
      :add-dialog="addDialog"
      :form-data="formData"
      @close="addDialog = false"
      @reset-table-data="getCompanySocialSecurity()"
    />

    <show-social-security
      :id="id"
      :show-dialog="showDialog"
      @reset-table-data="getCompanySocialSecurity()"
      @close="showDialog = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import AddSocialSecurity from '../AddSocialSecurity.vue'
import ShowSocialSecurity from '../ShowSocialSecurity.vue'
import Vue from 'vue'

export default {
  components: {
    AddSocialSecurity,
    ShowSocialSecurity,
  },

  data() {
    return {
      socialSecurities: [],
      formData: {},
      company: {},
      id: 0,
      addDialog: false,
      showDialog: false,
    }
  },

  mounted() {
    this.getCompanyData().then(() => {
      this.getCompanySocialSecurity()
    })
  },

  methods: {
    async getCompanyData() {
      await axios.get(`company/${this.$route.params.id}/`).then(res => {
        this.company = res.data
      })
    },

    isLastPaymentDateOld(row) {
      let lastMonthDate = Vue.moment(new Date()).subtract(1, 'months')
      let lastMonthDateYear = Number(Vue.moment(lastMonthDate).format('YYYY'))
      let lastMonthDateMonth = Number(Vue.moment(lastMonthDate).format('MM'))

      let lastPaymentDate = new Date(row.last_payment.input_date)
      let lastPaymentDateYear = Number(Vue.moment(lastPaymentDate).format('YYYY'))
      let lastPaymentDateMonth = Number(Vue.moment(lastPaymentDate).format('MM'))

      if (lastMonthDateYear > lastPaymentDateYear || lastMonthDateMonth > lastPaymentDateMonth) {
        return true
      }

      return false
    },

    getCompanySocialSecurity() {
      axios.get('social-security/', { params: { company_id: this.company.id } }).then(res => {
        this.socialSecurities = res.data
      })
    },

    addSocialSecurity() {
      this.formData = {
        company_id: this.company.id,
        profile_picture: null,
        id_card_picture: null,
        order_picture: null,
        full_name: null,
        input_date: null,
        salary: null,
      }
      this.addDialog = true
    },

    viewSocialSecurity(row) {
      this.id = row.id
      this.showDialog = true
    }
  },
}
</script>

<style lang="scss" scoped>
</style>