<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="900px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Social Security') }}
          <span class="blue--text ps-3">
            {{ data.full_name }}
          </span>
          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon class="mdil-36px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style="width: 180px">
                        {{ $t('Created At') }}
                      </th>
                      <th>{{ $t('Date') }}</th>
                      <th>{{ $t('Amount') }}</th>
                      <th>{{ $t('Last invoice image') }}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(row, index) in data.payments"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>
                        <span class="dir-ltr d-inline-block">
                          {{ $_date_time_format(row.created_at) }}
                        </span>
                      </td>
                      <td>{{ row.input_date }}</td>
                      <td>{{ $_number_format(row.amount) }}</td>
                      <td class="py-1">
                        <a
                          :href="`${$_file_path()}${row.attachment}?token=${$_auth_token()}`"
                          target="_blank"
                        >
                          <div style="width: 50px; height: 50px">
                            <img
                              :src="`${$_file_path()}${row.attachment}?token=${$_auth_token()}`"
                              style="width: 100%; height: 100%; object-fit: cover"
                              class="rounded"
                              alt=""
                            >
                          </div>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            @click="closeDialog()"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    showDialog: { type: Boolean, default: false },
    id: { type: Number, required: true },
  },

  data() {
    return {
      data: {
        full_name: null,
        input_date: null,
        salary: null,
        profile_picture: null,
        id_card_picture: null,
        payments: []
      },
      editMode: false,
    }
  },

  watch: {
    showDialog(val) {
      if (val) {
        this.getData()
      }
    },
  },

  methods: {
    async getData() {
      await axios.get(`social-security/${this.id}/client/`).then(res => {
        this.data = res.data
      })
    },

    closeDialog() {
      this.$emit('close')
    },
  },
}
</script>
