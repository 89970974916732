<template>
  <div>
    <v-dialog
      v-model="addDialog"
      persistent
      max-width="500px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Add New Social Security') }}
          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon class="mdil-36px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <validation-observer
          ref="theForm"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(submit)"
            @reset.prevent="reset"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Full Name')"
                    rules="required"
                  >
                    <v-text-field
                      v-model="formData.full_name"
                      :label="`* ${$t('Full Name')}`"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Social Date')"
                    rules="required"
                  >
                    <app-date-picker
                      v-model="formData.input_date"
                      :label="`* ${$t('Social Date')}`"
                      outlined
                      dense
                      no-title
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  class="px-1"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Salary')"
                    rules="required"
                  >
                    <v-currency-field
                      v-model="formData.salary"
                      :label="`* ${$t('Salary')}`"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  class="mb-4"
                >
                  <app-image-uploader
                    v-if="addDialog"
                    width="90%"
                    height="180px"
                    label="Profile picture"
                    :parent-image-url="formData.profile_picture"
                    @image-selected="formData.profile_picture = $event"
                    @is-image-updated="formData.profile_image_updated = $event"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  class="mb-4"
                >
                  <app-image-uploader
                    v-if="addDialog"
                    width="90%"
                    height="180px"
                    label="National Card Picture"
                    :parent-image-url="formData.id_card_picture"
                    @image-selected="formData.id_card_picture = $event"
                    @is-image-updated="formData.id_card_image_updated = $event"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  class="mb-4"
                >
                  <app-image-uploader
                    v-if="addDialog"
                    width="90%"
                    height="180px"
                    label="Social Order Picture"
                    :parent-image-url="formData.order_picture"
                    @image-selected="formData.order_picture = $event"
                    @is-image-updated="formData.order_image_updated = $event"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions class="py-3">
              <app-form-tip />

              <v-spacer />

              <v-btn
                depressed
                outlined
                small
                @click="closeDialog()"
              >
                {{ $t('Cancel') }}
              </v-btn>

              <v-btn
                type="submit"
                color="primary"
                depressed
                small
              >
                <v-icon left>
                  mdil-check
                </v-icon>
                <span class="pe-2">
                  {{ $t('Add') }}
                </span>
              </v-btn>
            </v-card-actions>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    addDialog: { type: Boolean, default: false },
    formData: { type: Object, default: () => { } },
  },

  methods: {
    closeDialog() {
      this.$refs.theForm.reset()
      this.$emit('close')
    },

    submit() {
      let fd = new FormData()
      for (let key in this.formData) {
        if (this.formData[key]) {
          fd.append(key, this.formData[key])
        }
      }

      axios.post('social-security/', fd).then(() => {
        this.$_notify('Added successfully')
        this.closeDialog()
        this.$emit('reset-table-data')
      })
    }
  },
}
</script>
