<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="11"
        lg="9"
        xl="8"
      >
        <v-card outlined>
          <v-card-text>
            <v-tabs
              v-model="tab"
              active-class="active-tab"
              height="40px"
              slider-color="transparent"
            >
              <v-tab>{{ $t('Active') }}</v-tab>

              <v-tab>{{ $t('Archive') }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <ActiveSocial v-if="tab === 0" />
              </v-tab-item>

              <v-tab-item>
                <ArchivedSocial v-if="tab === 1" />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ActiveSocial from './sub/ActiveSocial.vue'
import ArchivedSocial from './sub/ArchivedSocial.vue'

export default {
  components: {
    ActiveSocial,
    ArchivedSocial,
  },

  data() {
    return {
      tab: null
    }
  },

}
</script>

<style lang="scss" scoped>
.active-tab {
  background-color: #3b6196 !important;
  color: white !important;
  border-radius: 0.5rem;
}
</style>
