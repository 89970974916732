<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="8"
        xl="7"
      >
        <v-card outlined>
          <v-card-text>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t('Logo') }}</th>
                    <th>{{ $t('Activity') }}</th>
                    <th>{{ $t('Created At') }}</th>
                    <th
                      style="width: 250px"
                      class="text-center"
                    >
                      <v-btn
                        depressed
                        color="primary"
                        small
                        @click="addNew()"
                      >
                        <v-icon
                          class="mdil-24px"
                          left
                        >
                          mdil-plus
                        </v-icon>
                        {{ $t('Add New') }}
                      </v-btn>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, index) in apiData"
                    :key="index"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="py-1">
                      <a
                        :href="`${row.logo}?token=${$_auth_token()}`"
                        target="_blank"
                      >
                        <div style="width: 50px; height: 50px">
                          <img
                            :src="`${row.logo}?token=${$_auth_token()}`"
                            style="width: 100%; height: 100%; object-fit: cover"
                            class="rounded"
                            alt=""
                          >
                        </div>
                      </a>
                    </td>
                    <td>
                      <div style="white-space: pre-line">
                        {{ row.activity }}
                      </div>
                    </td>
                    <td>
                      <span class="d-inline-block dir-ltr">
                        {{ $_date_time_format(row.created_at) }}
                      </span>
                    </td>
                    <td>
                      <div class="d-flex justify-space-between">
                        <v-btn
                          class="bg-info-tonal info--text"
                          small
                          text
                          depressed
                          @click="editRow(row)"
                        >
                          <v-icon left>
                            mdi-pen
                          </v-icon>
                          {{ $t('Edit') }}
                        </v-btn>
                        <v-btn
                          class="bg-error-tonal error--text"
                          small
                          text
                          depressed
                          @click="deleteRow(row.id)"
                        >
                          <v-icon left>
                            mdi-trash-can-outline
                          </v-icon>
                          {{ $t('Delete') }}
                        </v-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <AddEdit
      :add-edit-dialog="addEditDialog"
      :form-data="formData"
      :edit-mode="editMode"
      @close="addEditDialog = false"
      @reset-table-data="getData()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import AddEdit from './AddEdit.vue'

export default {
  components: {
    AddEdit,
  },

  data() {
    return {
      formData: {},
      addEditDialog: false,
      apiData: [],
      editMode: false,
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    async getData() {
      await axios.get(`company-business-symbol/`, { params: { company_id: this.$route.params.id } }).then(res => {
        this.apiData = res.data
      })
    },

    addNew() {
      this.formData = {
        company: this.$route.params.id,
        logo: null,
        activity: null,
      }
      this.editMode = false
      this.addEditDialog = true
    },

    editRow(row) {
      this.formData = {
        id: row.id,
        company: this.$route.params.id,
        logo: row.logo,
        activity: row.activity,
      }
      this.editMode = true
      this.addEditDialog = true
    },

    deleteRow(id) {
      this.$_confirm().then(value => {
        if (value) {
          axios.delete(`company-business-symbol/${id}/`, { params: { company_id: this.$route.params.id } }).then(() => {
            this.$_notify('Deleted successfully')
            this.getData()
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
