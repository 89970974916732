import axios from 'axios'
import store from '../store'
import i18n from './i18n'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

// Handle error
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    // If unauthorized
    if (error.request.status == 401) {
      store.commit('SET_LOGOUT')
    } else {
      if (error.request.status >= 400 && error.request.status <= 499) {
        if (error.response.data.message) {
          store.commit('SET_NOTIFICATION', {
            message: i18n.t(error.response.data.message),
            type: 'error',
            timeout: 5000
          })
        } else {
          let errors = Object.values(error.response.data).join('</li><li>')
          store.commit('SET_NOTIFICATION', {
            message: i18n.t(errors),
            type: 'error',
            timeout: 5000
          })
        }
      } else {
        store.commit('SET_NOTIFICATION', {
          message: i18n.t('Server error, please try again'),
          type: 'error',
          timeout: 3000
        })
      }
    }

    return Promise.reject(error)
  }
)
