<template>
  <div>
    <v-row>
      <!-- Account Statement -->
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
      >
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col class="text-h6">
                {{ $t('Accounting Actions') }}
              </v-col>

              <v-col cols="auto">
                <v-btn
                  depressed
                  small
                  @click="$_print()"
                >
                  <v-icon left>
                    mdil-printer
                  </v-icon>

                  {{ $t('Print') }}
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-simple-table
                  id="print-area"
                  class="print-table"
                >
                  <template #default>
                    <thead>
                      <tr>
                        <invoice-header />
                      </tr>

                      <tr>
                        <th
                          colspan="100"
                          class="text-subtitle-1 d-none d-print-table-cell font-weight-bold black--text"
                          style="height: 30px"
                        >
                          <table style="width: 100%">
                            <tr>
                              <th class="text-start">
                                {{ $t('Company Name') }}: {{ authShareholder.company.name }}
                              </th>
                              <th class="text-end">
                                {{ $t('Manager') }}: {{ authShareholder.company.manager }}
                              </th>
                            </tr>
                          </table>
                        </th>
                      </tr>

                      <tr>
                        <th>#</th>
                        <th>{{ $t('Type') }}</th>
                        <th>{{ $t('Invoice No') }}</th>
                        <th class="d-print-none">
                          {{ $t('Invoice Picture') }}
                        </th>
                        <th>{{ $t('Description') }}</th>
                        <th>{{ $t('Credit') }}</th>
                        <th>{{ $t('Debt') }}</th>
                        <th>{{ $t('Balance') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, accIndex) in apiData.data"
                        :key="accIndex"
                      >
                        <td>{{ accIndex + 1 }}</td>
                        <td>
                          <v-chip
                            label
                            small
                            :color="getChipColor(row.action_type)"
                          >
                            {{ $t(row.action_type) }}
                          </v-chip>
                        </td>
                        <td>{{ row.invoice_no }}</td>
                        <td class="d-print-none">
                          <a
                            v-if="row.attachments.length"
                            :href="`${$_file_path()}${row.attachments[0].attachment}?token=${$_auth_token()}`"
                            target="_blank"
                            @click.stop
                          >
                            <v-icon>mdil-eye</v-icon>
                          </a>
                        </td>
                        <td>
                          <span
                            class="d-inline-block d-md-none text-truncate"
                            style="max-width: 200px;"
                          >
                            <v-tooltip
                              top
                              color="black"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ row.description }}
                                </span>
                              </template>
                              <span>{{ row.description }}</span>
                            </v-tooltip>
                          </span>
                          <span class="d-none d-md-inline-block">
                            {{ row.description }}
                          </span>
                        </td>
                        <td>{{ $_number_format(row.credit) }}</td>
                        <td>{{ $_number_format(row.debit) }}</td>
                        <td>{{ $_number_format(row.balance) }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr
                        v-if="apiData.totals"
                        class="font-weight-bold grey"
                        :class="{
                          'darken-3': $vuetify.theme.dark,
                          'lighten-3': !$vuetify.theme.dark,
                        }"
                      >
                        <td colspan="4" />
                        <td class="d-print-none" />
                        <td>{{ $_number_format(apiData.totals.credit) }}</td>
                        <td>{{ $_number_format(apiData.totals.debit) }}</td>
                        <td>{{ $_number_format(apiData.totals.balance) }}</td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      apiData: {},
      debits: [],
      formData: {},
      editMode: false,
      addEditDialog: false,
      url: '',
      title: '',
      companyRegistrationCost: 0,
    }
  },

  computed: {
    ...mapGetters(['authShareholder'])
  },

  mounted() {
    this.getAccStmtData()
  },

  methods: {
    getChipColor(type) {
      if (type == 'Expense') return 'error'
      if (type == 'Receipt') return 'success'
      if (type == 'Debit') return 'primary'
    },

    getAccStmtData() {
      axios.post('client/report/my-company-account-statement', {}).then(res => {
        this.apiData = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>