var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","transition":"slide-y-transition"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('v-card',{staticClass:"dialog-card-border"},[_c('v-card-title',{staticClass:"text-h6 font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t('Add New Social Security'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',{staticClass:"mdil-36px mdil-rotate-45"},[_vm._v(" mdil-plus ")])],1)],1),_c('validation-observer',{ref:"theForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Full Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("* " + (_vm.$t('Full Name'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.full_name),callback:function ($$v) {_vm.$set(_vm.formData, "full_name", $$v)},expression:"formData.full_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Social Date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('app-date-picker',{attrs:{"label":("* " + (_vm.$t('Social Date'))),"outlined":"","dense":"","no-title":"","error-messages":errors},model:{value:(_vm.formData.input_date),callback:function ($$v) {_vm.$set(_vm.formData, "input_date", $$v)},expression:"formData.input_date"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Salary'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"label":("* " + (_vm.$t('Salary'))),"outlined":"","dense":"","error-messages":errors},model:{value:(_vm.formData.salary),callback:function ($$v) {_vm.$set(_vm.formData, "salary", $$v)},expression:"formData.salary"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12","sm":"6"}},[(_vm.addDialog)?_c('app-image-uploader',{attrs:{"width":"90%","height":"180px","label":"Profile picture","parent-image-url":_vm.formData.profile_picture},on:{"image-selected":function($event){_vm.formData.profile_picture = $event},"is-image-updated":function($event){_vm.formData.profile_image_updated = $event}}}):_vm._e()],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12","sm":"6"}},[(_vm.addDialog)?_c('app-image-uploader',{attrs:{"width":"90%","height":"180px","label":"National Card Picture","parent-image-url":_vm.formData.id_card_picture},on:{"image-selected":function($event){_vm.formData.id_card_picture = $event},"is-image-updated":function($event){_vm.formData.id_card_image_updated = $event}}}):_vm._e()],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12","sm":"6"}},[(_vm.addDialog)?_c('app-image-uploader',{attrs:{"width":"90%","height":"180px","label":"Social Order Picture","parent-image-url":_vm.formData.order_picture},on:{"image-selected":function($event){_vm.formData.order_picture = $event},"is-image-updated":function($event){_vm.formData.order_image_updated = $event}}}):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('app-form-tip'),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","outlined":"","small":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","depressed":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdil-check ")]),_c('span',{staticClass:"pe-2"},[_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }